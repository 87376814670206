/**
 * Button
 */

import React from 'react';
import classNames from 'classnames';
import { useStyle } from 'Themes/theme';

import sD from './ButtonLink.Dansskolanentre.module.scss';
import sK from './ButtonLink.Kulturama.module.scss';
import sM from './ButtonLink.Medborgarskolan.module.scss';
import sP from './ButtonLink.Pahlmans.module.scss';
import sT from './ButtonLink.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const ButtonLink = ({
    href = '',
    title,
    text = '',
    target,
    rel,
    ariaLabel,
    modifiers = [],
    componentClass = '',
}) => {
    const s = useStyle(styles);

    const classes = classNames(
        componentClass,
        s['ButtonLink'],
        modifiers.map((m) => [s[`ButtonLink--${m}`]]),
    );

    return (
        <a
            className={classes}
            href={href}
            title={title}
            target={target}
            rel={rel}
            aria-label={ariaLabel}
        >
            <span
                className={s['ButtonLink__Text']}
                dangerouslySetInnerHTML={{__html: text}}
            />
        </a>
    );
};

export default ButtonLink;
